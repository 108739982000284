import cx from "classnames";
import React, { useEffect, useState } from "react";
import styles from "./pricing.module.css";
import generalStyle from "app.module.css";
import { Button } from "elements/buttons";
import config from ".config";
import { MultiSelect } from "general/shared/select";
import { DetailItem } from "general/shared/info-section/item";
import { Icon } from "assets/icons";
import { SimpleInput } from "elements/inputs";
import { Spacer } from "general/shared/layouts/generic";
import * as ModuleService from "services/module";
import { Switch } from "general/shared/switch";

const currencies = [
  { value: "NGN", label: "NGN" },
  { value: "USD", label: "USD" },
  { value: "KES", label: "KES" },
  { value: "GHS", label: "GHS" },
];

const modules = [
  { value: "campaign", label: "Campaigns" },
  { value: "transactional", label: "Transactional emails" },
  { value: "automation", label: "Automations" },
];

const price_scale = [
  { min: 0, max: 499999, rates: { NGN: 1, KES: 0.08, GHS: 0.0098, USD: 0.00083 } },
  { min: 500000, max: 999999, rates: { NGN: 0.875, KES: 0.07, GHS: 0.0085, USD: 0.000723 } },
  { min: 1000000, max: 1499999, rates: { NGN: 0.8, KES: 0.064, GHS: 0.0078, USD: 0.00067 } },
  { min: 1500000, max: 1999999, rates: { NGN: 0.78, KES: 0.062, GHS: 0.0076, USD: 0.00065 } },
  { min: 2000000, max: 2999999, rates: { NGN: 0.75, KES: 0.06, GHS: 0.0073, USD: 0.00063 } },
  { min: 3000000, max: 3999999, rates: { NGN: 0.72, KES: 0.057, GHS: 0.007, USD: 0.0006 } },
  { min: 4000000, max: 4999999, rates: { NGN: 0.7, KES: 0.056, GHS: 0.0068, USD: 0.00058 } },
  { min: 5000000, max: 9999999, rates: { NGN: 0.65, KES: 0.052, GHS: 0.0063, USD: 0.00054 } },
  {
    min: 10000000,
    max: Number.MAX_SAFE_INTEGER,
    rates: { NGN: 0.6, KES: 0.48, GHS: 0.0059, USD: 0.00083 },
  },
];

const SubscriptionPlan = ({ currency = {}, selected_module = {} }) => {
  const [discount_rate, setDiscountRate] = useState(0);
  const [price, setPrice] = useState(0);
  const [pricing, setPricing] = useState(0);
  const [selected_pricing, setSelectedPricing] = useState(0);
  const [is_annual, setIsAnnual] = useState(false);

  useEffect(() => {
    if (!selected_module.value) return;

    const { pricing: mod_pricing } = selected_module;
    const parsed_pricing = (mod_pricing || []).map((price) => {
      const { contact_bracket, _id } = price;
      const label = `${contact_bracket.min} - ${contact_bracket.max}`;
      return { ...price, label, value: _id };
    });

    setPricing(parsed_pricing);
    setSelectedPricing(parsed_pricing[0]);
  }, [selected_module]);

  useEffect(() => {
    const { discounts, prices } = selected_pricing;
    const { amount } = prices?.find((price) => price.currency === currency.value) || { amount: 0 };

    let discount = 0;
    let plan_amount = is_annual ? amount * 12 : amount;
    if (discounts && discounts.month && !is_annual) {
      discount = (plan_amount * discounts.month.value) / 100;
      setDiscountRate(discounts.month.value);
    }

    if (discounts && discounts.year && is_annual) {
      discount = (plan_amount * discounts.year.value) / 100;
      setDiscountRate(discounts.year.value);
    }

    const cost = (Number(plan_amount) - Number(discount)).toFixed(2);
    setPrice(() => Number(cost).toLocaleString());
    // eslint-disable-next-line
  }, [selected_module.value, currency.value, selected_pricing.value, is_annual]);

  const handleSignupPage = async () => {
    const utm_query = window.localStorage.getItem("utm");
    window.location.href = `${config.app_url}/register?${utm_query}`;
  };

  const benefits = [
    "20X Campaigns per month",
    "Unlimited transactional emails",
    "Unlimited automation actions",
    "Drag & Drop editor",
    "Unlimited audiences",
    "Subscriber attribution",
    "Attribute-based segmentation",
    "Advanced performance reports",
    "Unlimited users",
    "Role-based access control",
    "In-app knowledge center",
    "Customer support",
  ];

  return (
    <div className={cx(styles.planWrapper, styles.subscription)}>
      <h4 className={styles.planTitle}>{selected_module.label} Subscription</h4>
      <div className={styles.planPrice}>
        <span>{price}</span> {currency.value}
      </div>
      <div className={styles.switchWrapper}>
        <Switch checked={is_annual} onChange={setIsAnnual} />{" "}
        <span>yearly {discount_rate > 0 && `(${discount_rate}%)`}</span>
      </div>
      <Spacer multiple={4} />
      <DetailItem title="Number of Contacts">
        <MultiSelect options={pricing} isSorted={false} value={selected_pricing} onChange={setSelectedPricing} />
      </DetailItem>
      <div className={styles.benefits}>
        {benefits.map((benefit) => (
          <div key={benefit} className={styles.benefit}>
            <Icon className={styles.icon} height={20} width={20} name="check" />
            <span>{benefit}</span>
          </div>
        ))}
      </div>
      <Spacer multiple={8} />
      <Button text="Get Started" onClick={handleSignupPage} className={styles.btn} icon_name="arrow_right_flat" />
    </div>
  );
};

const UsagePlan = ({ currency = {} }) => {
  const [price, setPrice] = useState(0);
  const [subscribers, setSubscribers] = useState(0);

  useEffect(() => {
    const { rates } =
      price_scale.find((scale) => scale.min <= subscribers && scale.max >= subscribers) ||
      price_scale[price_scale.length - 1];
    const cost = (rates[currency.value] * subscribers).toFixed(2);
    setPrice(() => Number(cost).toLocaleString());
    // eslint-disable-next-line
  }, [currency.value, subscribers]);

  const handleSignupPage = async () => {
    const utm_query = window.localStorage.getItem("utm");
    window.location.href = `${config.app_url}/register?${utm_query}`;
  };

  const benefits = [
    "1 credit per email sent",
    "3 credits per executed action",
    "Drag & Drop editor",
    "Unlimited subscribers",
    "Unlimited audiences",
    "Subscriber attribution",
    "Attribute-based segmentation",
    "Advanced performance reports",
    "Unlimited users",
    "Role-based access control",
    "In-app knowledge center",
    "Customer support",
  ];

  return (
    <div className={cx(styles.planWrapper, styles.usage)}>
      <h4 className={styles.planTitle}>Pay-As-You-Use</h4>
      <div className={styles.planPrice}>
        <span>{price}</span> {currency.value}
      </div>
      <DetailItem title="Est. Monthly Email Volume">
        <SimpleInput value={subscribers} onInput={setSubscribers} />
      </DetailItem>
      <div className={styles.benefits}>
        {benefits.map((benefit) => (
          <div key={benefit} className={styles.benefit}>
            <Icon className={styles.icon} height={20} width={20} name="check" />
            <span>{benefit}</span>
          </div>
        ))}
      </div>
      <Spacer multiple={8} />
      <Button text="Get Started" onClick={handleSignupPage} className={styles.btn} icon_name="arrow_right_flat" />
    </div>
  );
};

export const Pricing = () => {
  const [currency, setCurrency] = useState(currencies[0]);
  const [modules_options, setModuleOptions] = useState([]);
  const [selected_module, setSelectedModule] = useState({});

  useEffect(() => {
    ModuleService.read().then((response) => {
      const { error, payload } = response.data;
      if (error) return;

      const { data: res_modules } = payload;
      const module_map = res_modules.reduce(
        (sac, module) => ({
          ...sac,
          [module.code]: module,
        }),
        {}
      );

      const options = modules.map((module) => ({ ...(module_map[module.value] || {}), ...module }));
      setModuleOptions(options);
      setSelectedModule(options[0]);
    });
  }, []);

  return (
    <div className={generalStyle.gmContainer}>
      <section id="pricing" className={styles.wrapper}>
        <div className={styles.pricingWrapper}>
          <div className={styles.pricingInfo}>
            <h1 className={styles.heading}>Pricing that scales according to your needs.</h1>
            <p>Achieve greater conversions on a flexible and cost-efficient budget.</p>
            <p>
              With support for usage-based payments as well as feature-based subscriptions, you are in total control of
              what you use and what you pay for - completely adaptable and suitable for all your marketing strategies.
            </p>
            <p>Take full control and optimize your email marketing budget while maximizing your reach and impact.</p>
          </div>

          <div className={styles.plansSection}>
            <div className={styles.currencyWrapper}>
              <div className={styles.moduleSelector}>
                <DetailItem title="Select Product">
                  <MultiSelect options={modules_options} value={selected_module} onChange={setSelectedModule} />
                </DetailItem>
              </div>
              <div className={styles.currency}>
                <DetailItem title="Currency">
                  <MultiSelect options={currencies} value={currency} onChange={setCurrency} />
                </DetailItem>
              </div>
            </div>
            <div className={styles.plansWrapper}>
              <UsagePlan currency={currency} />
              <SubscriptionPlan currency={currency} selected_module={selected_module} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Pricing;
