import React, { useEffect, useState } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import styles from "app.module.css";
import { Header } from "general/header";
import { Main } from "general/main";
import { Features } from "general/features";
import { Pricing } from "general/pricing";
import { Reviews } from "general/reviews";
import { Footer } from "general/footer";
import { PrivacyPolicy } from "general/privacy";
import { TermsAndConditions } from "general/terms";
import { ContactSection } from "general/contact";
import { Unsubscribe } from "general/unsubscribe";
import Documentation from "developers";
import { Partners } from "general/partners";
import { useQuery } from "hooks/query";

const MainLayout = () => (
  <>
    <Main />
    <Partners />
    <Features />
    <Reviews />
    <Pricing />
    <Footer />
  </>
);

const AppSections = () => {
  const [, setShowLogo] = useState(true);
  return (
    <div className={styles.pageWrapper}>
      <Header />
      <Routes>
        <Route path="/features" element={<Features />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact" element={<ContactSection />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/unsubscription/:tracking_id/:email/:id" element={<Unsubscribe setShowLogo={setShowLogo} />} />
        <Route path="*" element={<MainLayout />} />
      </Routes>
    </div>
  );
};

export const App = () => {
  const query = useQuery();

  useEffect(() => {
    if (!Object.keys(query).length) return;
    window.localStorage.setItem("utm", window.location.search.slice(1));
  }, [query]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/developers/*" element={<Documentation />} />
        <Route path="*" element={<AppSections />} />
      </Routes>
      <Outlet />
    </BrowserRouter>
  );
};
